export const MenuItems = [
  {
    title: "Home",
    url: "/home",
    cName: "nav-links",
  },
  {
    title: "Projects",
    url: "/projects",
    cName: "nav-links",
  },
  {
    title: "About Me",
    url: "/aboutme",
    cName: "nav-links",
  },
  // {
  //   title: "Resume",
  //   url:
  //     "/https://docs.google.com/document/d/1Rod90JVxwBtrxPw-Xenov_VCaorl2W26LcdAYkfg37Y/edit?usp=sharing",
  //   cName: "nav-links",
  // },
  {
    title: "Contact Me",
    url: "contact",
    cName: "nav-links",
  },
];
